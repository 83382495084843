@tailwind base;
@tailwind components;

a,
button,
:focus {
  outline: none;
}

.btn {
  @apply rounded font-semibold text-xl min-h-action transition duration-300 ease-in-out;
}

.btn:focus {
  @apply outline-none;
}

.global-rich-text {
  @apply break-words;

  a {
    @apply underline;
  }
}

.link-tertiary {
  @apply text-primary underline;

  &:active,
  &:focus {
    @apply text-primary;
  }
  &:hover {
    @apply text-primary;
  }
}

.is-primary {
  @apply bg-primary text-white;

  &:active,
  &:focus {
    @apply bg-primary text-white;
  }
  &:hover {
    @apply bg-primary-darken text-white;
  }
}

.is-secondary {
  @apply bg-secondary text-primary;

  &:active,
  &:focus {
    @apply bg-secondary text-primary;
  }
  &:hover {
    @apply bg-secondary-darken text-primary;
  }
}

.is-secondary-disable {
  @apply bg-inverse-darken text-gray-500;
}

.is-tertiary {
  @apply bg-tertiary text-tertiary-text;

  &:active,
  &:focus {
    @apply bg-tertiary-darken text-tertiary-text;
  }
  &:hover {
    @apply bg-tertiary-darken text-tertiary-text;
  }
}

.is-inverse {
  @apply bg-inverse-darken text-black;

  &:active,
  &:focus {
    @apply bg-inverse-darken text-black;
  }
  &:hover {
    @apply bg-inverse-darkest text-black;
  }
}

.is-inverse-event {
  @apply bg-inverse text-black;

  &:active,
  &:focus {
    @apply bg-inverse text-black;
  }
  &:hover {
    @apply bg-inverse-darken text-black;
  }
}

.is-white {
  @apply bg-white text-black;

  &:active,
  &:focus {
    @apply bg-inverse-lighten text-primary-lighten;
  }
  &:hover {
    @apply bg-primary text-white;
  }
}

.is-mood-option {
  @apply bg-inverse;

  &:active,
  &:focus {
    @apply bg-primary hover:bg-primary-darken;
  }
  &:hover {
    @apply bg-inverse-darken;
  }
}

.is-dark {
  @apply bg-tertiary text-white;

  &:active,
  &:focus {
    @apply bg-tertiary text-white;
  }
  &:hover {
    @apply bg-tertiary-darken text-white;
  }
}

.is-checked-in {
  @apply bg-inverse-darken text-gray-400;

  &:active,
  &:focus {
    @apply bg-inverse-darken text-gray-400;
  }
  &:hover {
    @apply bg-inverse-darken text-gray-400;
  }
}

.is-transparent {
  @apply bg-transparent;
}

/*buttons or links*/
.action {
  &.is-xs {
    @apply px-2;
  }
  &.is-sm {
    @apply px-4;
  }
  &.is-in {
    @apply px-6;
  }
  &.is-md {
    @apply px-8;
  }
  &.is-lg {
    @apply px-16;
  }
}

.gridContainer {
  @apply grid justify-center gap-4 mb-8;

  grid-template-rows: auto;
  --grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

  @screen sm {
    --grid-template-columns: repeat(auto-fill, 300px);
  }

  grid-template-columns: var(--grid-template-columns);
}

@tailwind utilities;

:root {
  --black: #1e293b;
  --white: #fff;
  --gray-100: #f1f5f9;
  --gray-200: #f0f0f0;
  --gray-300: #d8d8d8;
  --gray-400: #bfbfbf;
  --gray-500: #64748b;
  --gray-600: #939393;
  --gray-700: #696969;
  --gray-800: #404040;
  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4; /** teal/200 */
  --teal-900: #134e4a;

  --red-100: #f97773;
  --red-200: #f85651;
  --red-300: #de4d48;
  --red-700: #b91c1c;

  --beige-100: #f4f5ef;
  --beige-200: #eef1ef;

  --brand-blue-light: #44c7f3;

  --primary-header-height: 83px;
  --secondary-header-height: 113px;
}

.theme-life,
.theme-connect {
  --black: #0f172a; /** blue-gray/900 */

  /* blue-gray colors */
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-450: #738198;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
  /* teal colors */
  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;

  --color-bg-primary-lighten: var(--teal-600);
  --color-bg-primary: var(--teal-700);
  --color-bg-primary-darken: var(--teal-800);
  --color-bg-primary-darkest: var(--teal-900);

  --color-bg-secondary-lighten: var(--teal-50);
  --color-bg-secondary: var(--teal-50);
  --color-bg-secondary-darken: var(--teal-100);
  --color-bg-secondary-darkest: var(--teal-200);

  --color-text-tertiary: var(--black);
  --color-bg-tertiary-lighten: var(--gray-100);
  --color-bg-tertiary: var(--gray-300);
  --color-bg-tertiary-darken: var(--gray-400);

  --color-bg-inverse-lighten: var(--gray-50);
  --color-bg-inverse: var(--gray-100);
  --color-bg-inverse-darken: var(--gray-300);
  --color-bg-inverse-darkest: var(--gray-400);

  --color-bg-accent-lighten: var(--gray-50);
  --color-bg-accent: var(--gray-100);
  --color-bg-accent-darken: var(--gray-200);
}

.theme-reach {
  --color-bg-primary-lighten: #50596b;
  --color-bg-primary: #3a4558;
  --color-bg-primary-darken: #243046;
  --color-bg-primary-darkest: var(--color-bg-primary-darken);

  --color-bg-secondary-lighten: #738198;
  --color-bg-secondary: #334562;
  --color-bg-secondary-darken: #29364a;
  --color-bg-secondary-darkest: var(--color-bg-secondary-darken);

  --color-text-tertiary: var(--black);
  --color-bg-tertiary-lighten: #2771d5;
  --color-bg-tertiary: #1c51b9;
  --color-bg-tertiary-darken: #204896;

  --color-bg-inverse-lighten: var(--beige-100);
  --color-bg-inverse: var(--beige-200);
  --color-bg-inverse-darken: #738198;
  --color-bg-inverse-darkest: var(--color-bg-inverse-darken);

  --color-bg-accent-lighten: #abe6ff;
  --color-bg-accent: #90deff;
  --color-bg-accent-darken: #8bd9fa;
}

.theme-elevate {
  --black: #120a19; /** wine/900 */

  /* blue-gray colors */
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-450: #738198;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
  /* teal colors */
  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;
  /* wine colors */
  --wine-50: #f2f0f4;
  --wine-100: #d9d3de;
  --wine-200: #c0b7c7;
  --wine-300: #a79bb0;
  --wine-400: #8e8099;
  --wine-500: #756581;
  --wine-600: #5b4b68;
  --wine-700: #42324f;
  --wine-800: #2a1b37;
  --wine-900: #120a19;

  --color-bg-primary-lighten: var(--wine-600);
  --color-bg-primary: var(--wine-700);
  --color-bg-primary-darken: var(--wine-800);
  --color-bg-primary-darkest: var(--wine-900);

  --color-bg-secondary-lighten: var(--wine-50);
  --color-bg-secondary: var(--wine-50);
  --color-bg-secondary-darken: var(--wine-100);
  --color-bg-secondary-darkest: var(--wine-200);

  --color-text-tertiary: var(--white);
  --color-bg-tertiary-lighten: var(--gray-600);
  --color-bg-tertiary: var(--gray-700);
  --color-bg-tertiary-darken: var(--gray-800);

  --color-bg-inverse-lighten: var(--gray-50);
  --color-bg-inverse: var(--gray-100);
  --color-bg-inverse-darken: var(--gray-300);
  --color-bg-inverse-darkest: var(--gray-400);

  --color-bg-accent-lighten: var(--gray-50);
  --color-bg-accent: var(--gray-100);
  --color-bg-accent-darken: var(--gray-200);
}

.theme-thrive {
  --color-bg-primary-darken: #261c2e;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

body {
  font-weight: 400;
  font-size: 16px;
  color: var(--black);
}

ul,
li > ul {
  list-style: none;
  text-indent: 0;
  list-style-type: disc;
  list-style-position: inside;
}

ol,
li > ol {
  list-style: none;
  text-indent: 0;
  list-style-type: decimal;
  list-style-position: inside;
}

.z-main {
  /* Add this class to every element that needs to be shown over the narration button */
  z-index: 998;
}

.z-footer {
  z-index: 997;
}

.z-overlay {
  z-index: 100000;
  opacity: 0;
}

.primary-header {
  position: -webkit-sticky;
  position: sticky;
  height: var(--primary-header-height);
}

.primary-header header {
  height: var(--primary-header-height);
}

.secondary-header {
  position: -webkit-sticky;
  position: sticky;
  top: calc(var(--primary-header-height));
  z-index: 40;
}

.secondary-header-single {
  top: 0;
}

button:disabled {
  color: var(--black);
}

.disable-lighther-txt {
  color: var(--gray-500);
}

button:disabled:not(.bg-transparent):not(.is-transparent) {
  background-color: var(--gray-300);
}

input:not([placeholder*="Search"])::placeholder {
  font-weight: 400;
}

input:disabled {
  background-color: var(--gray-200);
}

textarea::placeholder {
  font-weight: 400;
}

[class*="stonly-banner-content"] {
  font-size: 20px !important;
}

[aria-label="Close Stonly widget"] svg {
  background-color: #646464 !important;
  width: 50px !important;
  height: 50px !important;
  transition: background-color 0.2s ease 0s;
  border-radius: 50% !important;
}

input.profileCheckbox[type=checkbox] {
  position: relative;
  cursor: pointer;
}

input.profileCheckbox[type=checkbox]:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 44px;
  top: -4px;
  left: 0;
  border: none;
  border-radius: 3px;
  padding: 1px;
  background-color: var(--gray-300);
}

input.profileCheckbox[type=checkbox]:checked:before {
  background-color: var(--teal-600);
}

input.profileCheckbox[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 5px;
  left: 17px;
}

.service-description a{
  text-decoration: underline;
  color: rgba(15, 118, 112, 1);
}